import React, {useEffect, useState} from 'react'
import logo from './logo.svg';
import './App.css';

function App() {
  const[dumps, setDumps] = useState({})
  const[err, setBadError] = useState('')

  const fetchFauna = () => {
    fetch("http://localhost:8787/products/360974480380527185")
    .then(res => {
      return res.json()
    }).then(data => {
      setDumps(data)
    }).
    catch(err => 
        setBadError(err)
      )
  }

  useEffect(() => {
    fetchFauna()
  }, [])

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
        <div>
          <p>
            {dumps} 
          </p>
          <p>
            {err}
          </p>
        </div>
      </header>
    </div>
  );
}

export default App;
